import Vue from 'vue';
import Vuex from 'vuex';

import idb from './api/idb';
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  media:[]
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  }
}

const actions = {
  async deleteMedia(context, med) {
    console.log('store is being asked to delete '+med.id);
    await idb.deleteMedia(med); 
  },

  async getMedia(context) {
    context.state.med = [];
    let med = await idb.getMedia();
    med.forEach(c => {
      context.state.med.push(c);
    });
    // console.log(med,'Kok')
  },
  async saveMedia(context, med) {
    await idb.saveMedia(med);
  }
}
export default new Vuex.Store({
  state,
  mutations,
  actions
})