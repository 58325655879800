import Vue from 'vue'
import Router from 'vue-router'

const Main = () =>
    import ('@/views/pages/Main')

Vue.use(Router)

export default new Router({
        mode: 'hash',
        // hash
        // https://router.vuejs.org/api/#mode
        linkActiveClass: 'open active',
        scrollBehavior: () => ({ y: 0 }),
        routes: [
            {
                path: '/',
                name: 'Main',
                component: Main
            },
            {
                path: '/room/:room_no',
                name: 'Main',
                component: Main
            }
        ]
    },


    // ===========================================

    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
)